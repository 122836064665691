import { responsiveOnBreakpoints } from '@doltech/core/lib/responsive/responsive.util';
import cl from 'classnames';
import * as React from 'react';
import styled from 'styled-components';

import { colorsV2 } from '../../../../figma/colors-v2';
import { ColumnGap, CommonProps, RowGap } from '../PageSectionModel';

const Item = styled.div``;

export interface ResponsiveBreakpoint {
  breakpoint: number;
  numOfColumn: number;
}

const Container = styled.section<{
  columns: ResponsiveBreakpoint[];
  gap?: [ColumnGap, RowGap];
}>`
  display: grid;
  column-gap: ${(p) => p.gap[0]}px;
  row-gap: ${(p) => p.gap[1]}px;

  &.grid-column-divider {
    column-gap: ${(p) => p.gap[0] * 2}px;
    overflow: hidden;

    ${Item} {
      position: relative;

      &:not(:last-child):after {
        content: '';
        border-right: 1px solid ${colorsV2.black20};
        position: absolute;
        top: 0;
        bottom: 0;
        right: -${(p) => p.gap[0]}px;
      }
    }
  }

  &.grid-row-divider {
    row-gap: ${(p) => p.gap[1] * 2}px;

    ${Item} {
      position: relative;

      &:not(:last-child):after {
        content: '';
        border-bottom: 1px solid ${colorsV2.black20};
        position: absolute;
        bottom: -${(p) => p.gap[1]}px;
        left: 0;
        right: 0;
      }
    }
  }

  ${(p) =>
    responsiveOnBreakpoints(
      p.columns,
      (item) => `grid-template-columns: repeat(${item.numOfColumn || 1}, minmax(0, 1fr));`
    )}
`;

export interface GridContentProps extends CommonProps {
  gridColumn?: ResponsiveBreakpoint[];
  gap?: number | [ColumnGap, RowGap];
  withColumnDivider?: boolean;
  withRowDivider?: boolean;
}

export const GridContentV2 = (props: GridContentProps) => {
  const {
    data,
    renderCard,
    gridColumn: columns,
    gap = 24,
    withColumnDivider = false,
    withRowDivider = false,
    specialCard,
  } = props;

  let gridGap = [gap, gap];
  if (Array.isArray(gap)) {
    gridGap = gap;
  }

  return (
    <Container
      className={cl('grid-content-main', {
        'grid-column-divider': withColumnDivider,
        'grid-row-divider': withRowDivider,
      })}
      columns={columns}
      gap={gridGap as [ColumnGap, RowGap]}
    >
      {(data || [])?.map((item, index) => {
        const withSpecialCard = false;
        return (
          <React.Fragment key={item.id || index}>
            <Item>{renderCard(item, index)}</Item>
            {withSpecialCard && specialCard.renderCard(item, index)}
          </React.Fragment>
        );
      })}
    </Container>
  );
};
