export const PROMOTION_TYPE = {
  BUY_COURSE: 'buy-course',
  SIGN_UP: 'sign-up',
  ALMOST_EXPIRED: 'almost-expired',
  EXPIRED: 'expired',
};

export const DIRECTION_TYPE = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
};

export interface SectionPromotionCommonProps {
  actionTitle: string;
  description: string;
  title: string | React.ReactNode;
  actionHref?: string;
  imageUrl?: string;
  hrefOptions?: any;
  actionOnClick?: (config?: any) => void;
}

export interface SectionPromotionLoaderProps extends SectionPromotionCommonProps {
  direction?: (typeof DIRECTION_TYPE)[keyof typeof DIRECTION_TYPE];
  short?: boolean;
  type: (typeof PROMOTION_TYPE)[keyof typeof PROMOTION_TYPE];
}
