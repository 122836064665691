import dynamic from 'next/dynamic';
import * as React from 'react';

import { PROMOTION_TYPE, DIRECTION_TYPE, SectionPromotionLoaderProps } from './type';

const SectionPromotionAlmostExpiredShortNoVertical = dynamic<any>(() =>
  import('./AlmostExpired/Vertical/SectionPromotionAlmostExpiredShortNoVertical').then(
    (mod) => mod.SectionPromotionAlmostExpiredShortNoVertical
  )
);

const SectionPromotionExpiredShortNoVertical = dynamic<any>(() =>
  import('./Expired/Vertical/SectionPromotionExpiredShortNoVertical').then(
    (mod) => mod.SectionPromotionExpiredShortNoVertical
  )
);

const SectionPromotionBuyCourseShortNoVertical = dynamic<any>(() =>
  import('./BuyCourse/Vertical/SectionPromotionBuyCourseShortNoVertical').then(
    (mod) => mod.SectionPromotionBuyCourseShortNoVertical
  )
);

const SectionPromotionSignupShortNoVertical = dynamic<any>(() =>
  import('./SignUp/Vertical/SectionPromotionSignupShortNoVertical').then(
    (mod) => mod.SectionPromotionSignupShortNoVertical
  )
);

const SectionPromotionAlmostExpiredShort = dynamic<any>(() =>
  import('./AlmostExpired/Short/SectionPromotionAlmostExpiredShort').then(
    (mod) => mod.SectionPromotionAlmostExpiredShort
  )
);
const SectionPromotionAlmostExpiredShortNo = dynamic<any>(() =>
  import('./AlmostExpired/ShortNo/SectionPromotionAlmostExpiredShortNo').then(
    (mod) => mod.SectionPromotionAlmostExpiredShortNo
  )
);

const SectionPromotionBuyCourseShort = dynamic<any>(() =>
  import('./BuyCourse/Short/SectionPromotionBuyCourseShort').then(
    (mod) => mod.SectionPromotionBuyCourseShort
  )
);
const SectionPromotionBuyCourseShortNo = dynamic<any>(() =>
  import('./BuyCourse/ShortNo/SectionPromotionBuyCourseShortNo').then(
    (mod) => mod.SectionPromotionBuyCourseShortNo
  )
);

const SectionPromotionExpiredShort = dynamic<any>(() =>
  import('./Expired/Short/SectionPromotionExpiredShort').then(
    (mod) => mod.SectionPromotionExpiredShort
  )
);
const SectionPromotionExpiredShortNo = dynamic<any>(() =>
  import('./Expired/ShortNo/SectionPromotionExpiredShortNo').then(
    (mod) => mod.SectionPromotionExpiredShortNo
  )
);

const SectionPromotionSignupShort = dynamic<any>(() =>
  import('./SignUp/Short/SectionPromotionSignupShort').then(
    (mod) => mod.SectionPromotionSignupShort
  )
);
const SectionPromotionSignupShortNo = dynamic<any>(() =>
  import('./SignUp/ShortNo/SectionPromotionSignupShortNo').then(
    (mod) => mod.SectionPromotionSignupShortNo
  )
);

export const SectionPromotion = (props: SectionPromotionLoaderProps) => {
  const { type, short, direction = 'horizontal', ...rest } = props;
  const isVertical = direction === DIRECTION_TYPE.VERTICAL;
  switch (type) {
    case PROMOTION_TYPE.SIGN_UP:
      if (isVertical) return <SectionPromotionSignupShortNoVertical {...rest} />;
      if (short) return <SectionPromotionSignupShort {...rest} />;
      return <SectionPromotionSignupShortNo {...rest} />;
    case PROMOTION_TYPE.BUY_COURSE:
      if (isVertical) return <SectionPromotionBuyCourseShortNoVertical {...rest} />;
      if (short) return <SectionPromotionBuyCourseShort {...rest} />;
      return <SectionPromotionBuyCourseShortNo {...rest} />;
    case PROMOTION_TYPE.ALMOST_EXPIRED:
      if (isVertical) return <SectionPromotionAlmostExpiredShortNoVertical {...rest} />;
      if (short) return <SectionPromotionAlmostExpiredShort {...rest} />;
      return <SectionPromotionAlmostExpiredShortNo {...rest} />;
    case PROMOTION_TYPE.EXPIRED:
      if (isVertical) return <SectionPromotionExpiredShortNoVertical {...rest} />;
      if (short) return <SectionPromotionExpiredShort {...rest} />;
      return <SectionPromotionExpiredShortNo {...rest} />;
  }
};
