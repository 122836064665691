import { IconContentMathEqual } from '@doltech/icons/IconContentMathEqual';
import { IconSystemV2CourseArrowChevronRightArrowRight } from '@doltech/icons/SystemV2/IconSystemV2CourseArrowChevronRightArrowRight';
import { Meta } from '@doltech/ui/lib/figma/Card/DolCard/components/Meta';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { AspectRatio } from '@doltech/ui/lib/figma/Common/AspectRatio';
import { Typography } from '@doltech/ui/lib/figma/DesignSystemV2/Typography';
import { Link } from '@doltech/ui/lib/figma/Link';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import { useDeviceDetect } from '@doltech/ui/lib/hooks/useDeviceQuery.hook';
import { imgProxyLoader } from '@doltech/utils/lib/image-loader';
import { getLandingUrlFromPath } from '@doltech/utils/lib/url';

import cl from 'classnames';
import Image from 'next/image';
import numeral from 'numeral';
import * as React from 'react';
import styled from 'styled-components';
import { Grid } from 'styled-css-grid';

export interface BookOverviewCardProps {
  data: any;
  isHorizontalDisplay?: boolean;
  imageWidth?: number; // Use for isHorizontalDisplay case.
  withDescription?: boolean;
  dividerShown?: boolean;
  isPreview?: boolean;
  imgPriority?: boolean;
}

export const BookOverviewCard = (props: BookOverviewCardProps) => {
  const {
    data,
    isHorizontalDisplay = false,
    imageWidth,
    withDescription = false,
    dividerShown = false,
    isPreview = false,
    imgPriority = false,
  } = props;

  const { isMobile } = useDeviceDetect();

  const viewTestsUrl = data.seoPage?.url ? getLandingUrlFromPath(data.seoPage?.url) : undefined;

  const renderTitle = React.useMemo(() => {
    return (
      <TitleWrapper className="book-card-title">
        <Link href={viewTestsUrl} className={cl('title-link', { disabled: !viewTestsUrl })}>
          <ResponsiveTypography.Text
            as="h3"
            variant={['semi-bold/16-24', 'semi-bold/20-28']}
            breakpoints={[0, 768]}
            ellipsis={isHorizontalDisplay ? false : { rows: 2 }}
          >
            {data.name}
          </ResponsiveTypography.Text>
        </Link>
        <Meta
          metadata={[
            {
              id: 'NO_OF_TESTS',
              text: `${data?.noOfTests || 0} bài tests`,
              textColor: 'black80',
            },
            {
              id: 'TEST_TAKEN',
              text: `${numeral(data.testTakers).format('0, 0a') || 0} lượt làm`,
              textColor: 'black80',
            },
          ]}
        />
      </TitleWrapper>
    );
  }, [data.name, data.noOfTests, data.testTakers, isHorizontalDisplay, viewTestsUrl]);

  const renderDetail = React.useCallback(
    (item) => {
      return (
        <StyleGrid
          key={item.id}
          rows={isHorizontalDisplay ? 1 : 'max-content auto'}
          columns={isHorizontalDisplay ? 'max-content auto' : 1}
          gap="16px"
          className="book-card-wrapper"
        >
          {isPreview && (
            <Move>
              <IconContentMathEqual width={24} height={24} color={colorsV2.black100} />
            </Move>
          )}
          <ImageWrapper
            href={viewTestsUrl}
            style={{ width: imageWidth || '100%' }}
            className={cl({ disabled: !viewTestsUrl })}
          >
            <AspectRatio ratio={3 / 2}>
              <Image
                layout="fill"
                objectFit="cover"
                src={item.thumbnail?.url || 'https://dummyimage.com/200x200.png'}
                alt={item.thumbnail?.alt || item.name}
                loader={imgProxyLoader}
                priority={isMobile ? false : imgPriority}
              />
            </AspectRatio>
          </ImageWrapper>
          <InfoWrapper className={cl('book-card-info', { withDescription: !viewTestsUrl })}>
            {renderTitle}
            {withDescription && data.seoPage?.dol?.description && (
              <Typography.Paragraph
                variant="regular/14"
                color="black80"
                ellipsis={{ rows: 3 }}
                mb="12px"
              >
                {data.seoPage?.dol?.description}
              </Typography.Paragraph>
            )}
            <ViewTestsButton
              href={viewTestsUrl}
              className={cl('book-card-view-tests-button', { disabled: !viewTestsUrl })}
            >
              <Typography.Text variant="semi-bold/14-20" color="primary100">
                Xem bài test
              </Typography.Text>
              <IconSystemV2CourseArrowChevronRightArrowRight
                fontSize={16}
                color={colorsV2.primary100}
              />
            </ViewTestsButton>
          </InfoWrapper>
          {dividerShown && <Divider className="divider" />}
        </StyleGrid>
      );
    },
    [
      data.seoPage?.dol?.description,
      dividerShown,
      imageWidth,
      isHorizontalDisplay,
      isMobile,
      isPreview,
      renderTitle,
      viewTestsUrl,
      withDescription,
      imgPriority,
    ]
  );

  if (!data) {
    return null;
  }

  return renderDetail(data);
};

const StyleGrid = styled(Grid)`
  position: relative;
`;
const ViewTestsButton = styled(Link)`
  width: fit-content;
  display: grid;
  grid-auto-flow: column;
  column-gap: 4px;
  align-items: center;
  border-bottom: 1px solid ${colorsV2.white100};
  padding: 2px 0;

  &:hover {
    border-color: ${colorsV2.primary100};
  }
  &.disabled {
    pointer-events: none;
  }
`;
const InfoWrapper = styled.div`
  height: max-content;
  .book-card-title {
    margin-bottom: 8px;
  }
  &.withDescription {
    .book-card-title {
      margin-bottom: 4px;
    }
  }
`;
const ImageWrapper = styled(Link)`
  img {
    background-color: ${colorsV2.gray20};
    border-radius: 8px;
  }
  &.disabled {
    pointer-events: none;
  }
`;
const Divider = styled.section`
  width: 1px;
  height: 100%;
  background-color: ${colorsV2.black20};
  position: absolute;
  top: 0;
  right: -32px;
`;
const TitleWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  row-gap: 4px;
  .title-link {
    font-size: 20px;
    font-weight: 700;
    color: ${colorsV2.black100};
    &:hover {
      text-decoration: ${colorsV2.black100} underline;
    }
    overflow: hidden;
    text-overflow: ellipsis;
    &.disabled {
      pointer-events: none;
    }
  }
`;
const Move = styled.div`
  position: absolute;
  transform: translate(24px, 24px);
  padding: 8px;
  border-radius: 8px;
  background: ${colorsV2.gray10};
  cursor: move;
  z-index: 2;
  width: 40px;
  height: 40px;
`;
