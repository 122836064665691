import * as React from 'react';
import { StyledComponentProps } from 'styled-components';

import { Box, BoxProps } from './BoxV2';

type AspectRatioProps = StyledComponentProps<
  'div',
  any,
  {
    /** The aspect ratio to apply */
    ratio?: number | number[];
  } & BoxProps,
  never
>;

export const AspectRatio = React.forwardRef<HTMLDivElement, AspectRatioProps>(
  ({ ratio = 16 / 9, children, ...props }, ref) => {
    let paddingBottom: any;
    if (Array.isArray(ratio)) {
      paddingBottom = ratio.map((r) => `${100 / r}%`);
    } else {
      paddingBottom = `${100 / ratio}%`;
    }

    return (
      <Box ref={ref} position="relative" overflow="hidden" width="100%">
        <Box width="100%" height={0} paddingBottom={paddingBottom} />
        <Box {...props} position="absolute" top={0} right={0} bottom={0} left={0}>
          {children}
        </Box>
      </Box>
    );
  }
);
