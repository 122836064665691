import { useFromScreens } from '../../../../lib/hooks/useDeviceQuery.hook';
import { s3ToCDN } from '@doltech/utils/lib/url';
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useUserAuthentication } from '../../Auth/hocs/withUserAuthentication';
import { useGetReferralCode } from '../../ReferralShare/useGetReferralCode';
import { useExpiredModal } from './hooks/useExpiredModal';
import { SectionPromotion } from './ui/SectionPromotion/SectionPromotion';
import { useGetAuthInfo } from '@doltech/core/lib/auth/withGlobalUserAuthInfo';
import { tuhocPrice } from '@doltech/core/lib/entity/tuhoc-price';

interface LandingPromotionLoaderProps {
  short?: boolean;
}

export const LandingPromotionLoader = observer((props: LandingPromotionLoaderProps) => {
  const { short = false } = props;
  const { isLoggedIn } = useGetAuthInfo();
  const [, from768, from880] = useFromScreens([0, 768, 880]);
  const { openLoginModal } = useUserAuthentication();
  const { isExpired, remainingDays } = useGetReferralCode();
  const { openExtendPremiumModal } = useExpiredModal();
  const showShortPromotion = !from768 || short;

  const showShortExpired = !from880 || short;

  if (isLoggedIn) {
    if (isExpired) {
      return (
        <SectionPromotion
          type="expired"
          short={showShortExpired}
          title="Gia hạn miễn phí!"
          description="Tài khoản của bạn đã hết hạn sử dụng. Hãy gia hạn ngay để tiếp tục việc học nhé!"
          actionTitle="Gia hạn miễn phí"
          actionOnClick={openExtendPremiumModal}
          imageUrl={s3ToCDN(
            'https://dol-english-resource-dev.s3.ap-southeast-1.amazonaws.com/PUBLIC/MEDIA/Frame_50126695_ea91ba2657.png'
          )}
        />
      );
    }
    if (remainingDays && remainingDays >= 0 && remainingDays <= 3) {
      return (
        <SectionPromotion
          type="almost-expired"
          short={showShortExpired}
          title="Tài khoản gần hết hạn"
          description={`Tài khoản của bạn chỉ còn ${remainingDays} ngày sử dụng. Gia hạn ngay để không làm gián đoạn việc học nhé!`}
          actionTitle="Gia hạn miễn phí"
          actionOnClick={openExtendPremiumModal}
          imageUrl={s3ToCDN(
            'https://dol-english-resource-dev.s3.ap-southeast-1.amazonaws.com/PUBLIC/MEDIA/Frame_50126694_76e0bf056c.png'
          )}
        />
      );
    }
    return (
      <SectionPromotion
        type="buy-course"
        short={showShortPromotion}
        title="Tìm hiểu khóa IELTS tại DOL"
        description={`Tìm hiểu khóa học IELTS Tư Duy tại DOL và nhận ngay code tự học 6 tháng trị giá ${tuhocPrice.DOLPRO_6M} khi đăng ký nhập học.`}
        actionTitle="Tư vấn miễn phí"
        actionHref="https://m.me/dolenglish.ieltsdinhluc"
        hrefOptions={{
          target: '_blank',
        }}
        imageUrl={s3ToCDN(
          'https://dol-english-resource-dev.s3.ap-southeast-1.amazonaws.com/PUBLIC/MEDIA/dolenglish_moment_slide_1ef476829eb4a6c8_23eebed015.png'
        )}
      />
    );
  }

  return (
    <SectionPromotion
      type="sign-up"
      short={showShortPromotion}
      title="Đăng ký tài khoản"
      description="Đăng ký để mở khóa ngay các tính năng premium của tuhocielts.dolenglish.vn"
      actionTitle="Đăng ký miễn phí ngay"
      actionOnClick={openLoginModal}
      imageUrl={s3ToCDN(
        'https://dol-english-resource-dev.s3.ap-southeast-1.amazonaws.com/PUBLIC/MEDIA/sign_up_banner_620a3396c3.png'
      )}
    />
  );
});
